import { makeVariant } from '../../../types/makeVariant'

export const cards = makeVariant({
  primary: {
    padding: 4,
    borderRadius: 4,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
  },
  bottomStatus: {
    backgroundColor: 'background',
  },
  sectionnav: {
    variant: 'layout.base',
    backgroundColor: ['muted', undefined, 'background'],
    height: [
      'var(--bottom-toolbar-height)',
      undefined,
      'var(--top-toolbar-height)',
    ],
    flex: '0 0 auto',
    boxShadow: [
      '0 0 5px rgba(0, 0, 0, 0.3)',
      undefined,
      '0px 24px 5px -24px rgba(0, 0, 0, 0.3);',
    ],
    px: [0, 0, 4],
  },
  clear: {
    padding: 4,
  },
  compact: {
    variant: 'cards.clear',
    padding: 4,
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'border',
  },
  info: {
    variant: 'cards.compact',
    py: 3,
    px: 4,
    border: '1px solid',
    borderColor: 'border',
    color: 'text',
  },
  success: {
    variant: 'cards.info',
    color: 'successText',
    borderColor: 'success',
    backgroundColor: 'success',
  },
  message: {
    variant: 'cards.info',
    color: 'messageText',
    borderColor: 'message',
    backgroundColor: 'message',
    borderRadius: 2,
    py: 3,
    px: 3,
  },
  warning: {
    variant: 'cards.info',
    color: 'warningText',
    borderColor: 'warning',
    backgroundColor: 'warning',
  },
  danger: {
    variant: 'cards.info',
    color: 'dangerText',
    borderColor: 'danger',
    backgroundColor: 'danger',
  },
  successOutline: {
    variant: 'cards.info',
    borderColor: 'success',
  },
  warningOutline: {
    variant: 'cards.info',
    borderColor: 'warning',
  },
  dangerOutline: {
    variant: 'cards.info',
    borderColor: 'danger',
    '> hgroup:first-child': {
      'h1, h2, h3': {
        color: 'danger',
      },
    },
  },
  debug: {
    variant: 'cards.info',
    borderColor: 'danger',
    borderStyle: 'dashed',
  },
  popup: {
    variant: 'cards.primary',
    background: '#f9f9f9',
    p: 0,
    color: '#515151',
    fontSize: 2,
    wordBreak: 'break-all',
  },
  photoUpload: {
    '--border-color': 'rgb(var(--rgb-border) / 0.7)',
    variant: 'cards.primary',
    backgroundColor: 'background',
  },
  photoUploadSuccess: {
    '--border-color': 'rgba(var(--rgb-background),0.7)',
    variant: 'cards.primary',
    backgroundColor: 'primary',
    color: 'primaryText',
  },
})
